<template>
    <OnBoardingCard :nextHandler="handleSubmit" displayImage="/images/resized/image3.png" flexClass="flex"
        nextButtonName="Next" :stepIndex="3">
        <div class="h1 reg">Add Zilch for personal use</div>
        <div class="form-field">
            <div class="instructions">
                <div class="instructions-block b1 semi">Cardholder</div>
            </div>
            <InputComponent icon-src="people/user" v-model="cardName" type="text" placeholder="Name on card"
                :validation="['required']" @validation="message => handleKeyPress('cardName', message)" />
        </div>
        <div class="form-field">
            <div class="instructions">
                <div class="instructions-block b1 semi">Card details</div>
            </div>
            <div id="card-element" class="b2 reg"></div>
        </div>
        <template v-slot:footer>How do we store your information? Read our <a href="/documents/privacy"
                target="_blank">Privacy Policy</a></template>
    </OnBoardingCard>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { mapState, mapMutations } from 'vuex';

export default {
    data() {
        return {
            cardName: '',
            stripe: null,
            elements: null,
            cardElement: null,
            tempToken: null,
        }
    },
    components: {
        OnBoardingCard
    },
    computed: {
        stripeCustomer() {
            return sessionStorage.getItem('tempCusToken') || null;
        },
        ...mapState(['onboarding']),
    },
    async mounted() {
        try {
            const stripeApiKey = this.$env.STRIPE_API_KEY;
            if (!stripeApiKey) {
                throw new Error("Stripe API key is not set");
            }
            this.stripe = await loadStripe(stripeApiKey);
            if (!this.stripe) {
                throw new Error("Stripe failed to initialize");
            }

            this.elements = this.stripe.elements();
            this.cardElement = this.elements.create('card', {
                hidePostalCode: true,
                disableLink: true,
                style: {
                    base: {
                        fontFamily: 'DM Sans, sans-serif',
                        fontSize: '14px'
                    }
                }
            });
            this.cardElement.on('change', ({ error }) => {
                if (error) {
                    this.setNextButtonEnabled(false);
                    console.error(error);
                    this.setGenericAlert(error.message);
                } else {
                    this.setNextButtonEnabled(true);
                }
            })
            this.cardElement.mount('#card-element');

            console.log('Stripe initialized successfully');
        } catch (error) {
            console.error("Error initializing Stripe:", error);
        }
    },
    unmounted() {
        if (this.cardElement) {
            this.cardElement.unmount();
        }
    },
    beforeUnmount() {
        if (this.stripeCustomer !== null) {
            sessionStorage.setItem('stripeCustomer', JSON.stringify(this.stripeCustomer));
        }
    },
    async beforeMount() {
        this.setNextButtonEnabled(false);
        if (!this.onboarding) {
            try {
                const sessionToken = sessionStorage.getItem('onboardingToken');
                const response = await this.$axios.get(`${this.$env.API_HOST}/user/onboarding`, {
                    headers: { token: this.onboarding?.token || sessionToken }
                })
                const data = response.data;
                if (!data) {
                    sessionStorage.removeItem('onboardingToken');
                    this.setTokenExpired(true);
                    this.$router.replace('/onboarding/join');
                }
                for (const key in data) {
                    this.setOnboarding({ key, value: data[key] });
                }
            } catch (error) {
                console.error("An error occurred while logging in:", error);
                this.setTokenExpired(true);
                this.$router.replace('/onboarding/join');
            }
        }
        if (this.stripeCustomer) {
            this.tempToken = this.stripeCustomer;
            this.setOnboarding({ key: 'payment_customer_token', value: this.stripeCustomer });
        } else if (this.onboarding && this.onboarding.first_name && this.onboarding.last_name && !this.stripeCustomer) {
            const name = this.onboarding.first_name.trim() + ' ' + this.onboarding.last_name.trim();
            const email = sessionStorage.getItem('onboardEmail');
            // Try searching for customer based on name or email
            let stripeCustomer = await this.$stripe.customers.search({
                query: `name:'${name}' AND email:'${email}'`
            })
            let customer = null;
            if (stripeCustomer && stripeCustomer.data.length > 0) {
                customer = stripeCustomer.data[0];
            } else {
                stripeCustomer = await this.$stripe.customers.search({
                    query: `email:'${email}'`
                })
                if (stripeCustomer && stripeCustomer.data.length > 0) {
                    customer = stripeCustomer.data[0];
                } else {
                    customer = await this.$stripe.customers.create({ name, email });
                }
            }
            this.tempToken = customer.id;
            sessionStorage.setItem('tempCusToken', this.tempToken);
            console.log('Stripe customer created', this.tempToken);
            this.setOnboarding({ key: 'payment_customer_token', value: this.tempToken });
        }
    },
    methods: {
        async handleSubmit() {
            this.setIsLoading(true);
            try {
                if (!this.cardName) {
                    console.error("Cardholder name is required");
                    return;
                }

                const { token, error } = await this.stripe.createToken(this.elements.getElement('card'), {
                    name: this.cardName,
                });

                if (error) {
                    throw error;
                } else {
                    const payment = await this.$stripe.customers.createSource(
                        this.tempToken,
                        {
                            source: token.id,
                        }
                    );
                    this.$axios.put(`${this.$env.API_HOST}/user/onboarding`, {
                        payment_customer_token: this.tempToken,
                        payment_method_token2: payment.id,
                        payment_method_tag2: 'private',
                    }, { headers: { token: sessionStorage.getItem('onboardingToken') } }).then(() => {
                        this.setOnboarding({ key: 'payment_method_token2', value: payment.id });
                        this.setOnboarding({ key: 'payment_method_tag2', value: 'Private' });
                        this.setIsLoading(false);
                        this.$router.push('/onboarding/add-access-card');
                    }).catch(error => {
                        this.setIsLoading(false);
                        this.setGenericAlert('There was an error during onboarding.');
                        console.error("An error occurred while logging in:", error);
                    });
                }
            } catch (error) {
                this.setIsLoading(false);
                this.setGenericAlert(error.message);
                console.error("An error occurred during submission:", error);
            }
        },
        handleKeyPress(name, message) {
            if (message === '') {
                this.setNextButtonEnabled(this.cardName);
            } else {
                this.setNextButtonEnabled(false);
            }
        },
        ...mapMutations(['setNextButtonEnabled', 'setOnboarding', 'setIsLoading', 'setGenericAlert'])
    }
}
</script>

<style scoped>
.forms {
    margin-bottom: 20px;
}

.double-form {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.forms-2col {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    width: 47%;
}

#card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    width: 100%;
}
</style>