<template>
    <OnBoardingCard :nextHandler="handleSubmit" displayImage="/images/resized/Carbn_Image 46.webp" nextButtonName="Next"
        :stepIndex="4">
        <div class="h1 reg">
            No keys required to get moving
        </div>
        <div class="block-text b2 reg">
            Your access card allows you to unlock any vehicle you&#039;ve booked.
        </div>
        <div class="form-field">
            <div class="instructions">
                <div class="form-field-label b1 reg">Access card number</div>
                <div class="form-field-hint b2 reg">
                    Input the number on the back of the card into your profile
                </div>
            </div>
            <InputComponent icon-src="payment/card" v-model="accessCardNumber" type="number" placeholder="0000 0000"
                :validation="['required']" @validation="handleKeyPress" />
        </div>
        <div class="buttons">
            <SelectableButton className="light" @click="handleSkipButton">
                <div class="btn-anchor bu1-semi">I don't have an access card</div>
            </SelectableButton>
        </div>
        <template v-slot:footer>How do we store your information? Read our <a href="/documents/privacy"
                target="_blank">Privacy Policy</a></template>
    </OnBoardingCard>
</template>
<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { setAuth } from '@/utils/functions';
import { mapMutations, mapState } from 'vuex';
export default {
    data() {
        return {
            accessCardNumber: '',
            validAccessCardNumber: true,
        }
    },
    components: {
        OnBoardingCard,
    },
    computed: {
        ...mapState(['onboarding']),
    },
    methods: {
        handleSubmit() {
            this.setIsLoading(true);
            const sessionToken = sessionStorage.getItem('onboardingToken');
            this.$axios.get(`${this.$env.API_HOST}/user/onboarding`, {
                headers: { token: this.onboarding.token || sessionToken }
            }).then(resp => {
                this.$axios.post(`${this.$env.API_HOST}/user/onboarding`, {
                    ...resp.data,
                    rfid: this.accessCardNumber
                }, { headers: { token: this.onboarding.token || sessionToken } }).then(response => {
                    this.setIsLoading(false);
                    if (response.status === 200 && response.data) {
                        setAuth(response.data);
                        this.$router.push('/onboarding/congratulations');
                    }
                }).catch(error => {
                    this.setIsLoading(false);
                    this.setGenericAlert('There was an error during onboarding.');
                    console.error("An error occurred while logging in:", error);
                });
            }).catch(err => {
                this.setIsLoading(false);
                this.setGenericAlert('There was an error during onboarding.');
                console.error("An error occurred while logging in:", err);
            });
        },
        handleKeyPress(message) {
            if (message === '') {
                this.setNextButtonEnabled(true);
            }
        },
        handleSkipButton() {
            this.$router.push('/onboarding/send-access-card');
            // this.$router.push('/onboarding/all-done');
        },
        ...mapMutations(['setNextButtonEnabled', 'setIsLoading', 'setGenericAlert'])
    }
}
</script>