export const callbacks = [
    {
        id: 'driver-licence',
        callback: (router, bus) => {
            sessionStorage.setItem('feedback', 'licence');
            router.push({ name: 'Profile' });
            bus.emit('switchToFeedback');
        }
    },
    {
        id: 'payment-methods',
        callback: (router, bus) => {
            sessionStorage.setItem('feedback', 'billing');
            router.push({ name: 'Profile' });
            bus.emit('switchToFeedback');
        }
    },
]