import { createStore } from 'vuex';
import { getAuth, setAuth, setIsAdmin, setWelcomeSession } from '@/utils/functions';
import { genericTags, topLevelMenu } from '@/utils/static';
import axios from 'axios';
import ENV from '@/env';
import { callbacks } from '@/utils/callbacks';

const store = createStore({
    state() {
        return {
            isLoggedIn: false, // Initial state is that the user is not logged in
            isFramed: false,
            isAdmin: false,
            onboarding: null,
            onboardingLoading: false,
            nextButtonEnabled: false,
            isMobile: window.innerWidth <= 821,
            widthBreakPoint: 821,
            isTokenExpired: false,
            notifications: [],
            searchCriteria: null,
            profile: null,
            refreshAttempted: false,
            menu: null,
            report: null,
            showWelcome: false,
            paymentMethods: [],
            costCentres: null,
            expenseCodes: null,
            company: null,
            genericAlert: null,
        };
    },
    mutations: {
        setGenericAlert(state, value) {
            state.genericAlert = value;
        },
        clearGenericAlert(state) {
            state.genericAlert = null;
        },
        setShowWelcome(state, value) {
            state.showWelcome = value;
            setWelcomeSession(value);
        },
        setRefreshAttempted(state, value) {
            state.refreshAttempted = value;
        },
        setIsLoading(state, value) {
            state.onboardingLoading = value;
        },
        setMenu(state, value) {
            state.menu = value;
        },
        setReport(state, value) {
            state.report = value;
        },
        setIsFramed(state, value) {
            state.isFramed = value;
        },
        setIsAdmin(state, value) {
            state.isAdmin = value;
            setIsAdmin(value);
        },
        setLoggedIn(state, value) {
            state.isLoggedIn = value;
        },
        setOnboarding(state, { key, value }) {
            if (state.onboarding === null) state.onboarding = {};
            state.onboarding[key] = value;
        },
        setProfile(state, { key, value }) {
            if (state.profile === null) state.profile = {};
            state.profile[key] = value;
        },
        setSearchCriteria(state, { key, value }) {
            if (state.searchCriteria === null) state.searchCriteria = {};
            if (value === null) {
                delete state.searchCriteria[key];
            } else {
                state.searchCriteria[key] = value;
            }
        },
        clearSearchCriteria(state) {
            sessionStorage.removeItem('searchParams');
            state.searchCriteria = null;
        },
        setNextButtonEnabled(state, value) {
            state.nextButtonEnabled = value;
        },
        setIsMobile(state, value) {
            state.isMobile = value;
        },
        setWidthBreakPoint(state, value) {
            state.widthBreakPoint = value;
        },
        setTokenExpired(state, value) {
            state.isTokenExpired = value;
        },
        pushNotification(state, value) {
            state.notifications.push(value);
        },
        pushPaymentMethod(state, value) {
            state.paymentMethods.push(value);
        },
        removeNotification(state, index) {
            state.notifications.splice(index, 1);
        },
        clearNotifications(state) {
            state.notifications = [];
        },
        logout(state) {
            // Save the properties you don't want to clear
            const isMobile = state.isMobile;
            const widthBreakPoint = state.widthBreakPoint;

            // Reset all other state properties
            Object.assign(state, {
                isLoggedIn: false,
                isFramed: false,
                isAdmin: false,
                onboarding: null,
                nextButtonEnabled: false,
                isTokenExpired: false,
                notifications: [],
                searchCriteria: null,
                profile: null,
                refreshAttempted: false,
                menu: null,
                report: null,
                showWelcome: false,
                paymentMethods: null,
                costCentres: null,
                expenseCodes: null,
                company: null,
            });

            // Restore the values you want to keep
            state.isMobile = isMobile;
            state.widthBreakPoint = widthBreakPoint;
        },
        loadNotifications(state, { messages, source, router, bus }) {
            messages.forEach((message, index) => {
                const callbackObj = callbacks.find(fn => fn.id === message.source)
                const callback = callbackObj?.callback ? () => callbackObj.callback(router, bus) : null;
                state.notifications.push({
                    id: `${source}:${index}`,
                    message,
                    class: message.type,
                    callback,
                })
            })
        },
    },
    actions: {
        refreshToken({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                commit('setRefreshAttempted', true);
                axios.post(`${ENV.API_HOST}/auth/login`, {
                    refresh_token: sessionStorage.getItem('refToken'),
                }).then((response => {
                    setAuth(response.data);
                    resolve(true);
                })).catch(e => {
                    console.error('Invalid credentials, need to re-login: ', e);
                    dispatch('logout');
                    reject(new Error(e));
                })
            })
        },
        loadProfile({ commit, dispatch, state }, { router, bus }) {
            return new Promise((resolve, reject) => {
                const accessToken = getAuth()
                axios.get(`${ENV.API_HOST}/user/details`, { headers: { Authorization: `Bearer ${accessToken}` } }).then(resp => {
                    state.profile = resp.data.data;
                    state.isLoggedIn = true;
                    commit('setIsAdmin', state.profile?.access === 'fleetmanager');
                    if (resp.data.messages && resp.data.messages.length > 0) {
                        commit('loadNotifications', { messages: resp.data.messages, source: 'profile', router, bus })
                    }
                    resolve(true);
                }).catch(e => {
                    console.error('Error fetching profile details with current accessToken:', e);
                    if (!state.refreshAttempted) {
                        dispatch('refreshToken')
                            .then(() => {
                                dispatch('loadProfile').then(() => {
                                    resolve(true)
                                }).catch(e => { reject(new Error(e)) });
                            })
                            .catch(e => { reject(new Error(e)) });
                    } else {
                        dispatch('logout');
                        reject(new Error(e));
                    }
                });
            })
        },
        fetchMenu({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${ENV.API_HOST}/admin/menus`, {
                    headers: { Authorization: `Bearer ${getAuth()}` }
                }).then(response => {
                    const allTags = {};
                    const menus = Object.keys(response.data).reduce((acc, key) => {
                        const keyTags = new Set();
                        const menu = [
                            ...(key === 'top_level') ? topLevelMenu : [],
                            ...response.data[key]
                        ].sort((a, b) => a.index - b.index);
                        acc[key] = menu.map(item => {
                            const tagTemplate = (key === 'top_level') ? genericTags : [];
                            if (item.name === 'Planner') item.icon = 'location/route';
                            const tags = item.tags ? item.tags.split(',').map(tag => tag.trim()) : tagTemplate;
                            tags.forEach(tag => keyTags.add(tag));
                            return {
                                ...item,
                                tags
                            }
                        });
                        allTags[key] = Array.from(keyTags);
                        return acc;
                    }, {});
                    menus.allTags = allTags;
                    if (response.data) commit('setMenu', menus);
                    resolve(true)
                }).catch(error => {
                    console.error(error);
                    reject(new Error(error))
                })
            });
        },
        fetchPaymentMethods({ commit, dispatch, state }, { router, bus }) {
            return new Promise((resolve, reject) => {
                axios.get(`${ENV.API_HOST}/user/paymentmethods`, {
                    headers: { 'Authorization': `Bearer ${getAuth()}` }
                }).then(response => {
                    if (response.status === 200 && response.data === null) {
                        resolve(true);
                        return;
                    }
                    const paymentMethods = state.paymentMethods = response.data.data ?? [];
                    if (response.data.messages && (response.data.messages.length > 0)) {
                        commit('loadNotifications', { messages: response.data.messages, source: 'payment', router, bus })
                    }

                    const corporatePayment = paymentMethods.find(method => method.service === 'employer');
                    state.company = corporatePayment?.tag;
                    dispatch('fetchCostCentres')
                        .then(() => resolve(true))
                        .catch(error => { reject(new Error(error)) });
                }).catch(error => {
                    console.error(error);
                    reject(new Error(error));
                })
            });
        },
        fetchCostCentres({ dispatch, state }) {
            return new Promise((resolve, reject) => {
                axios.get(`${ENV.API_HOST}/user/costcentres`, {
                    headers: { 'Authorization': `Bearer ${getAuth()}` }
                }).then(response => {
                    if (response.status === 200 && response.data === null) {
                        resolve(true);
                        return;
                    }
                    state.costCentres = response.data;
                    dispatch('fetchExpenseCodes')
                        .then(() => resolve(true))
                        .catch(error => { reject(new Error(error)) });
                }).catch(error => {
                    console.error(error);
                    reject(new Error(error));
                })
            });
        },
        fetchExpenseCodes({ state }) {
            return new Promise((resolve, reject) => {
                axios.get(`${ENV.API_HOST}/user/expensecodes`, {
                    headers: { 'Authorization': `Bearer ${getAuth()}` }
                }).then(response => {
                    state.expenseCodes = response.data;
                    resolve(true);
                }).catch(error => {
                    console.error(error);
                    reject(new Error(error));
                })
            });
        },
        login({ dispatch, state }, { email_address, password, router, bus }) {
            return new Promise((resolve, reject) => {
                axios.post(`${ENV.API_HOST}/auth/login`, {
                    email_address, password
                }).then(response => {
                    setAuth(response.data);
                    state.isLoggedIn = true;
                    dispatch('loadProfile', { router, bus }).then(() => {
                        if (state.isAdmin) {
                            dispatch('fetchMenu').then(() => {
                                resolve(true);
                            }).catch(e => {
                                reject(new Error(e))
                            })
                        }
                        dispatch('fetchPaymentMethods', { router, bus }).then(() => {
                            resolve(true)
                        }).catch(error => {
                            console.error(error);
                            reject(new Error(error));
                        })
                    }).catch(e => {
                        dispatch('logout');
                        reject(new Error(e));
                    });
                }).catch(e => {
                    dispatch('logout');
                    reject(new Error(e));
                });
            })
        },
        logout({ commit }) {
            sessionStorage.clear();
            localStorage.clear();
            commit('logout');
        },
        updateIsMobile({ commit }) {
            commit('setIsMobile', window.innerWidth <= this.state.widthBreakPoint);
        },
    }
});

export default store;
