<template>
    <div ref="button"
        :class="['button', className, { 'selected': isSelected, 'disabled': (isLoading || disabled) }]"
        :style="style">
        <LoadingDots v-if="isLoading" class="dark" />
        <slot v-else></slot>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import LoadingDots from '@/components/common/LoadingDots.vue';
export default {
    components: { LoadingDots },
    data() {
        return {
            buttonWidth: 0,
            buttonHeight: 0,
        };
    },
    props: {
        isSelected: {
            type: Boolean,
            default: false,
        },
        className: {
            type: String,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        style() { 
            if (this.isLoading === undefined) return;
            return { minWidth: this.buttonWidth + 'px', minHeight: this.buttonHeight + 'px' }
        },
        ...mapState(['isMobile'])
    },
    mounted() {
        this.updateButtonWidth();
        this.observeButtonResize();
    },
    beforeUnmount() {
        this.resizeObserver.disconnect();
    },
    methods: {
        updateButtonWidth() {
            if (this.$refs.button) {
                if (this.$refs.button.scrollWidth > this.buttonWidth) {
                   this.buttonWidth = this.$refs.button.scrollWidth;
                }
                if (this.$refs.button.scrollHeight > this.buttonHeight) {
                   this.buttonHeight = this.$refs.button.scrollHeight;
                }
            }
        },
        observeButtonResize() {
            this.resizeObserver = new ResizeObserver(() => {
                this.updateButtonWidth();
            });
            this.resizeObserver.observe(this.$refs.button);
        }
    }
};
</script>
<style scoped>
.button {
    color: var(--font-color, #042B2F);
    display: flex;
    padding: 15px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 8px;
    background: var(--color-primary, #A4EA6E);
    border: 1px solid var(--color-primary, #A4EA6E);
}

.button.small {
    padding: .875rem 1rem;
}

.button.xsmall {
    padding: .75rem .875rem;
}

.button.light {
    background: transparent;
    border: 1px solid var(--bg-secondary-color, #042B2F);
}

.button.dark {
    border: 1px solid var(--bg-secondary-color, #042B2F);
    background: var(--bg-secondary-color, #042B2F);
    color: var(--notification-colours-correct-light, #D6F6BC);
}

.button.red {
    border: 1px solid var(--notification-colours-error);
    background: var(--notification-colours-error);
    color: var(--lite-gray-white);
}

.button.red:hover {
    border: 1px solid var(--notification-colours-error-light);
    background: var(--notification-colours-error-light);
    color: var(--font-color);
}

.button.disabled {
    background-color: #D4D6C9;
    border-color: #D4D6C9;
    cursor: not-allowed;
}

.button.dark:not(.disabled):hover,
.button.light:not(.disabled):hover {
    background: var(--color-primary, #A4EA6E);
    border: 1px solid var(--color-primary, #A4EA6E);
    color: var(--font-color, #042B2F);
}

.button.borderless {
    border-color: transparent;
}

.button:not(.disabled):hover {
    border: 1px solid var(--bg-secondary-color, #042B2F);
    opacity: 1;
}

.button.selected {
    background: var(--notification-colours-correct-light, #D6F6BC);
    border-color: var(--notification-colours-correct-light, #D6F6BC);
}
</style>