<template>
    <div :class="['header', { 'scrolled': isScrolled, 'logged-in': isLoggedIn }]">
        <div class="left-header">
            <div class="company-logo">
                <div class="header-company-logos" @click="this.$router.push('/home')">
                    <img alt="logo" class="zilch-logo-full-colour-copy-jpeg-1"
                        src="/images/zilch-logo-full-colour-copy-jpeg-10.svg" />
                </div>
                <div class="b1 reg">2.0</div>
            </div>
            <div v-if="company && !isMobile" class="corporate-logo">
                <div class="h3 reg">|</div>
                <div class="h4 reg">{{ company.toUpperCase() }}</div>
            </div>
        </div>
        <div class="header-buttons" v-if="isLoggedIn">
            <div class="menu-buttons mobile" v-if="isMobile">
                <SelectableButton class="dark small" @click="reroute('/search')">
                    <div class="bu1 reg">Book</div>
                </SelectableButton>
                <DropDownButton class="is-right" :hasSubMenu="true">
                    <template #summary>
                        <SelectableButton class="light small borderless">
                            <SvgIcon name="menu/hamburger" class="icon-wide" />
                        </SelectableButton>
                    </template>
                    <SelectableButton class="light small borderless" @click="reroute('/home')">
                        <div class="bu1 reg">Upcoming</div>
                    </SelectableButton>
                    <SelectableButton class="light small borderless" @click="reroute('/history')">
                        <div class="bu1 reg">Historic</div>
                    </SelectableButton>
                    <SelectableButton class="light small borderless" @click="this.$router.push('/profile')">
                        <SvgIcon name="menu/profile" class="icon-wide" />
                        <div class="bu1 reg">{{ this.profileName }}</div>
                    </SelectableButton>
                    <SelectableButton class="light small borderless" @click="handleLogout">
                        <SvgIcon name="menu/logout" class="icon-wide" />
                        <div class="bu1 reg">Logout</div>
                    </SelectableButton>
                    <SelectableButton class="light small borderless" @click="returnToLegacy">
                        <div class="bu1 reg">Switch to Legacy</div>
                    </SelectableButton>
                </DropDownButton>
            </div>
            <div class="menu-buttons" v-else>
                <DropDownButton v-if="isAdmin">
                    <template #summary>
                        <SelectableButton class="light small borderless" @click="reroute({ name: 'Mobility Center' })">
                            <SvgIcon name="menu/hub-portal" class="icon-wide" />
                            <div class="my-rides">Mobility Centre</div>
                            <SvgIcon name="icon/down" />
                        </SelectableButton>
                    </template>
                    <SelectableButton v-for="item in menu?.top_level" :key="item.index" class="light small borderless"
                        @click="openTab(item.link)">
                        <SvgIcon :name="item.icon ?? 'menu/fleet'" class="icon-wide" />
                        <div class="my-rides">{{ item.name }}</div>
                    </SelectableButton>
                </DropDownButton>
                <DropDownButton :has-sub-menu="true" v-if="isAdmin">
                    <template #summary>
                        <SelectableButton class="light small borderless">
                            <SvgIcon name="menu/reports" class="icon-wide" />
                            <div class="my-rides">Insights</div>
                            <SvgIcon name="icon/down" />
                        </SelectableButton>
                    </template>
                    <DropDownButton ref="reports" class="sub-menu">
                        <template #summary>
                            <SelectableButton class="light small borderless" @click="reroute({ name: 'Reports' })">
                                <div class="submenu-head">
                                    <SvgIcon name="icon/inbox" class="icon-wide" />
                                    <div class="bu1 reg">Download Reports</div>
                                </div>
                                <SvgIcon name="icon/right" />
                            </SelectableButton>
                        </template>
                        <SelectableButton v-for="item in menu?.reports" :key="item.index" class="light small borderless"
                            @click="openTab(item.link)">
                            <div class="bu1 reg">{{ item.name }}</div>
                        </SelectableButton>
                    </DropDownButton>
                    <DropDownButton ref="insights" class="sub-menu">
                        <template #summary>
                            <SelectableButton class="light small borderless" @click="reroute({ name: 'Insights' })">
                                <div class="submenu-head">
                                    <SvgIcon name="menu/reports" class="icon-wide" />
                                    <div class="bu1 reg">Insights Centre</div>
                                </div>
                                <SvgIcon name="icon/right" class="is-right" />
                            </SelectableButton>
                        </template>
                        <SelectableButton v-for="item in menu?.insights" :key="item.index"
                            class="light small borderless" @click="openInsights(item.index)">
                            <div class="bu1 reg">{{ item.name }}</div>
                        </SelectableButton>
                    </DropDownButton>
                </DropDownButton>
                <DropDownButton>
                    <template #summary>
                        <SelectableButton class="light small borderless">
                            <SvgIcon name="car/car" class="icon-wide" />
                            <div class="my-rides">My Trips</div>
                            <SvgIcon name="icon/down" />
                        </SelectableButton>
                    </template>
                    <SelectableButton class="light small borderless" @click="reroute('/home')">
                        <div class="bu1 reg">Upcoming</div>
                    </SelectableButton>
                    <SelectableButton class="light small borderless" @click="reroute('/history')">
                        <div class="bu1 reg">Historic</div>
                    </SelectableButton>
                </DropDownButton>
                <DropDownButton>
                    <template #summary>
                        <SelectableButton class="light small borderless">
                            <SvgIcon name="menu/profile" class="icon-wide" />
                            <div class="bu1 reg">{{ this.profileName }}</div>
                            <SvgIcon name="icon/down" />
                        </SelectableButton>
                    </template>
                    <SelectableButton class="light small borderless" @click="reroute('/profile')">
                        <div class="bu1 reg">My Account</div>
                    </SelectableButton>
                    <SelectableButton class="light small borderless" @click="returnToLegacy">
                        <div class="bu1 reg">Switch to Legacy</div>
                    </SelectableButton>
                    <SelectableButton class="light small borderless" @click="handleLogout">
                        <SvgIcon name="menu/logout" class="icon-wide" />
                        <div class="bu1 reg">Logout</div>
                    </SelectableButton>
                </DropDownButton>
                <SelectableButton class="dark small" @click="reroute('/search')">
                    <SvgIcon name="car/car" class="icon-wide" color="white" />
                    <div class="bu1 reg">Book car</div>
                </SelectableButton>
            </div>
        </div>
    </div>
</template>
<script>
import DropDownButton from '@/components/common/DropDownButton.vue';
import { getAuth } from '@/utils/functions';
import { mapState, mapActions } from 'vuex';
export default {
    components: { DropDownButton },
    computed: {
        profileName() {
            return this.profile?.preferred_name || (`${this.profile?.first_name} ${this.profile?.last_name}`);
        },
        ...mapState(['isLoggedIn', 'isMobile', 'profile', 'isAdmin', 'menu', 'company'])
    },
    data() {
        return {
            isScrolled: false,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        reroute(route) {
            this.$bus.emit('close-all-menus');
            this.$router.push(route);
        },
        openTab(route) {
            this.$bus.emit('close-all-menus');
            this.$router.push(`/fleet/${route.replace(/^\/+|^https?:\/\/[^/]+\//, '')}`);
        },
        openInsights(index) {
            this.$bus.emit('close-all-menus');
            window.open(`/fleet/insight/${index}`);
        },
        subMenu(event) {
            event.stopPropagation();
        },
        handleScroll() {
            this.isScrolled = window.scrollY > 50;
        },
        handleLogout() {
            this.logout();
            this.$router.replace({ name: 'Login' })
        },
        returnToLegacy() {
            const encodedAccessToken = encodeURIComponent(getAuth());
            window.location.href = (`${this.$env.LEGACY_HOST}?z=${encodedAccessToken}`)
        },
        ...mapActions(['logout'])
    }
}
</script>
<style scoped>
.header {
    background: transparent;
    padding: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    transition: background-color 0.3s ease;
}

.header.scrolled {
    background: var(--lite-gray-grey-1-90percent);
}

.header.logged-in {
    justify-content: space-between;
    width: -webkit-fill-available;
}

.header-company-logos {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

.company-logo {
    cursor: pointer;
}

.corporate-logo {
    cursor: default;
}

.left-header,
.corporate-logo,
.company-logo {
    display: flex;
    gap: .5rem;
}

.corporate-logo {
    justify-content: flex-end;
    align-items: flex-end;
}

.left-header {
    gap: 1.5rem;
}

.header-buttons {
    display: flex;
    gap: 2.5rem;
}

.zilch-logo-full-colour-copy-jpeg-1 {
    flex-shrink: 0;
    width: 6.8rem;
    height: 2.7rem;
    position: relative;
    overflow: visible;
}

.menu-buttons {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    position: relative;
}

.menu-buttons .button {
    justify-content: flex-start;
}

.menu-buttons:not(.mobile) .details .button {
    min-width: 24rem;
}

.menu-buttons.mobile > :deep(details.dropdown > div > .details) {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: -7rem;
    right: -2.5rem;
    padding: 10rem 3rem;
    z-index: -1;
    border-radius: 0;
}

.menu-buttons.mobile .details .button {
    min-width: 15rem;
    height: 4.5rem;
    padding: 1rem 2.5rem;
}

.menu-buttons:not(.mobile) .details .sub-menu>summary>.button {
    justify-content: space-between;
}

.submenu-head {
    display: flex;
    gap: 1rem;
    align-items: center;
}

summary::-webkit-details-marker,
summary::marker {
    display: none;
}

summary {
    display: flex;
    gap: 2rem;
    align-items: center;
    cursor: pointer;
}

.sub-menu>summary>.button {
    width: -webkit-fill-available;
}

.sub-menu>summary>.button:hover {
    background-color: var(--color-primary);
}

details.dropdown.sub-menu>:last-child {
    position: absolute;
    top: 0;
    left: 21.5rem;
    padding-left: 3rem;
}

.button+.button {
    margin: auto;
}

.pill-button:hover {
    background-color: var(--color-primary);
}

@media (max-width: 600px) {
    
    .menu-buttons,
    .header-buttons {
        gap: 1.5rem;
    }
}

@media (max-width: 1024px) {
    .corporate-logo {
        display: none;
    }

    .menu-buttons {
        gap: 1rem;
    }
}
</style>