<template>
    <div :class="['header-alerts', 'container', extraClass]">
        <RowDiv class="alert-content">
            <SvgIcon name="icon/close" class="icon-wide pointer" @click="handleClose" />
            <ColDiv class="mobile-centered notification-message">
                <SvgIcon v-if="message.icon && !isMobile" :name="message.icon" class="icon-wide" />
                <div class="message-text">
                    <div class="b1 reg">
                        <div class="b1 semi">{{ message.title }}</div>
                        {{ message.note }}
                    </div>
                </div>
            </ColDiv>
            <ColDiv cols="1" class="mobile-centered" v-if="callback">
                <SvgIcon name="icon/right" class="icon-wide pull-right pointer" @click="handleClick" />
            </ColDiv>
        </RowDiv>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    props: {
        message: {
            type: Object,
            required: true
        },
        extraClass: {
            type: String,
            default: ''
        },
        callback: {
            type: Function,
            default: null,
        }
    },
    computed: {
        ...mapState(['isMobile'])
    },
    methods: {
        handleClick() {
            if (this.callback) {
                this.callback();
            }
        },
        handleClose() {
            this.$emit('close');
        },
    }
}
</script>
<style scoped>
.header-alerts {
    display: flex;
    padding: 15px 20px;
    flex-direction: column;
    gap: 25px;
    border-radius: 100px;
    color: #042B2F;
    width: -webkit-fill-available;
    border: 1px solid var(--color-grey, #FAFAFA);
    background: var(--lite-gray-grey-1-50percent);
}

.header-alerts.alert {
    border: 1px solid var(--notification-colours-error, #CA4D4D);
    background: var(--notification-colours-error-light, #FFECEC);
    color: #872121;
}

.header-alerts.success {
    border: 1px solid var(--notification-colours-correct, #0AAF6C);
    background: var(--notification-colours-correct-light, #D6F6BC);
}

.alert-content {
    display: flex;
    align-items: center;
    gap: 25px;
    align-self: stretch;
}

.row {
    margin: 0px;
}

.notification-message {
    gap: 2.5rem;
}

.message-text {
    gap: 1rem;
}

.message-text,
.notification-message {
    display: flex;
}

.message-text .b1.semi {
    display: contents;
}

.message-text .b1.reg {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.pointer {
    cursor: pointer;
}

.col,
[class*=" col-"],
[class*=col-] {
    margin: 0px;
}

@media (max-width: 820px) {
    .mobile-centered {
        flex: auto;
        max-width: 70vw;
    }

    .mobile-centered .message-text .b1.reg {
        flex-direction: column;
    }

    .header-alerts {
        border-radius: 0px;
        margin: -1rem;
    }

    .alert-content {
        gap: 1.25rem;
    }

    .header-alerts.error,
    .header-alerts.success,
    .header-alerts {
        border: 1px solid transparent;
    }

}
</style>