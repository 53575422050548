<template>
    <OnBoardingCard :nextHandler="handleSubmit" displayImage="/images/resized/Carbn_Image 46.webp" nextButtonName="Next"
        :stepIndex="4" className="small-gap">
        <div class="h1 reg">
            No keys required to get moving
        </div>
        <div class="block-text">
            Your access card allows you to unlock any vehicle you've booked.
        </div>
        <div class="form-field" ref="formField" v-if="addressUpdated === false">
            <div class="instructions">
                <div class="form-field-label">Delivery address</div>
                <div class="form-field-hint b2 reg">
                    Add your address so that we can get your access card to you
                </div>
            </div>
            <InputComponent v-model="accessCardDeliveryAddress" type="combo" placeholder="Start typing your address"
                :validation="['required']" icon-src="location/pin" @validation="message => handleInput(message)"
                @focus="handleFocus" />
            <ul v-if="addressList.length && showDropdown" class="dropdown">
                <li v-for="address in addressList" :key="address.id" @click="handleSelect(address.a)">{{ address.a }}
                </li>
            </ul>
        </div>
        <div v-if="addressUpdated">
            <p>Sent! Expect your card within the next few weeks</p>
        </div>
        <div class="buttons" v-else>
            <SelectableButton className="light" @click="handleSendAddress">
                <div class="btn-anchor bu1-semi">Send my access card</div>
            </SelectableButton>
        </div>
        <template v-slot:footer>How do we store your information? Read our <a href="/documents/privacy"
                target="_blank">Privacy Policy</a></template>
    </OnBoardingCard>
</template>
<script>
import OnBoardingCard from '@/components/OnBoardingCard.vue';
import { setAuth } from '@/utils/functions';
import { mapState, mapMutations } from 'vuex';

export default {
    components: {
        OnBoardingCard
    },
    computed: {
        ...mapState(['onboarding']),
    },
    data() {
        return {
            accessCardDeliveryAddress: '',
            timeoutId: null,
            addressList: [],
            showDropdown: false,
            addressUpdated: false,
        }
    },
    unmounted() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    mounted() {
        this.$bus.on('open-dropdown', this.handleFocus);
        document.addEventListener('click', this.handleClickOutside);
    },
    methods: {
        handleSubmit() {
            this.setIsLoading(true)
            const sessionToken = sessionStorage.getItem('onboardingToken');
            this.$axios.get(`${this.$env.API_HOST}/user/onboarding`, {
                headers: { token: this.onboarding.token || sessionToken }
            }).then(resp => {
                this.$axios.post(`${this.$env.API_HOST}/user/onboarding`, resp.data, {
                    headers: { token: this.onboarding.token || sessionToken }
                }).then(response => {
                    this.setIsLoading(false);
                    if (response.status === 200 && response.data) {
                        setAuth(response.data);
                        this.$router.push('/onboarding/congratulations');
                    }
                }).catch(error => {
                    this.setIsLoading(false);
                    this.setGenericAlert('There was an error during onboarding.');
                    console.error("An error occurred while logging in:", error);
                });
            }).catch();
        },
        handleSendAddress() {
            // Save Preferred Name
            const addressArray = this.accessCardDeliveryAddress.split(', ');
            const address = { street: '', street2: '', suburb: '', city: '', postcode: '' };
            if (addressArray.length < 3) {
                address.street = this.accessCardDeliveryAddress;
            } else {
                [address.city, address.postcode] = addressArray.pop().split(' ');
                address.suburb = addressArray.pop();
                address.street = addressArray.pop();
                address.street2 = (addressArray.length > 0) ? addressArray.join(', ') : '';
            }

            this.$axios.put(`${this.$env.API_HOST}/user/onboarding`, {
                send_card: true,
                ...address
            }, { headers: { token: this.onboarding.token } }).then(() => {
                this.addressUpdated = true;
                for (const key in address) {
                    this.setOnboarding({ key, value: address[key] });
                }
                this.setNextButtonEnabled(true);
            }).catch(error => {
                this.setGenericAlert('There was an error during onboarding.');
                console.error("An error occurred while logging in:", error);
            });
        },
        handleInput(message) {
            if (message === '') {
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => {
                    if (this.accessCardDeliveryAddress.trim()) {
                        this.searchAddy(this.accessCardDeliveryAddress.trim());
                    } else {
                        this.addressList = []
                    }
                }, 500)
            }
        },
        handleFocus() {
            if (this.accessCardDeliveryAddress.trim()) {
                this.searchAddy(this.accessCardDeliveryAddress.trim());
            }
        },
        handleSelect(address) {
            this.accessCardDeliveryAddress = address;
            this.addressList = [];
            this.showDropdown = false;
        },
        handleClickOutside(event) {
            if (!this.addressUpdated && !this.$refs.formField.contains(event.target)) {
                this.showDropdown = false; // Close dropdown if clicked outside the component
            }
        },
        searchAddy(query) {
            const headers = {
                'Accept': 'application/json',
                'addy-api-key': this.$env.ADDY_KEY,
            }
            const params = { s: query }
            this.$axios.get(this.$env.ADDY_URL, { headers, params }).then(response => {
                this.addressList = response.data.addresses;
                this.showDropdown = true;
            }).catch(error => {
                console.error('Error fetching addresses:', error);
                this.addressList = [];
            })
        },
        ...mapMutations(['setNextButtonEnabled', 'setOnboarding', 'setIsLoading', 'setGenericAlert'])
    }
}
</script>
<style scoped>
.forms-date {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    overflow: hidden;
}

.form-field-value,
input.form-field-value {
    color: var(--font-color, #042B2F);
    text-align: left;
    position: relative;
    flex: 1;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
    align-self: stretch;
}

.bo-forms-no-corners-labels {
    background: var(--color-grey, #FAFAFA);
    padding: 0.625rem;
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex: 1;
    position: relative;
    transform-origin: 0 0;
    transform: rotate(0deg) scale(1, 1);
    overflow: hidden;
}

.dropdown {
    position: absolute;
    top: 100%;
    /* Position dropdown below the input */
    left: 0;
    z-index: 10;
    /* Ensure dropdown appears above other content */
    width: 100%;
    /* Full width */
    background-color: #fff;
    /* Background color */
    border: 1px solid #ccc;
    /* Border for dropdown */
    max-height: 200px;
    /* Limit height if necessary */
    overflow-y: auto;
    /* Allow scrolling if overflow */
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* Optional: Add shadow for better visibility */
}

.dropdown li {
    padding: 8px 16px;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: #f0f0f0;
}
</style>