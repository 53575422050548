<template>
    <CardTopNavigation />
    <div class="onboarding-stack">
        <slot></slot>
    </div>
    <div class="display-panel">
        <CardBottomNavigation :stepIndex="stepIndex" :buttonText="nextButtonName" iconName="trip/arrow-right" :nextHandler="nextHandler" />
    </div>
</template>
<script>
import CardTopNavigation from '@/components/CardTopNavigation.vue';
import CardBottomNavigation from '@/components/CardBottomNavigation.vue';
export default {
    components: {
        CardTopNavigation,
        CardBottomNavigation
    },
    props: {
        className: String,
        nextButtonName: String,
        displayImage: {
            type: String,
            required: true,
        },
        flexClass: String,
        stepIndex: {
            type: Number,
            default: 0
        },
        nextHandler: Function,
    },
}
</script>