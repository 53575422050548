<template>
    <div :class="['modal', classes]">
        <div class="modal-content">
            <CardDiv>
                <RowDiv class="alert-content">
                    <slot></slot>
                </RowDiv>
                <RowDiv class="footer row">
                    <SelectableButton class="light small" @click="this.$emit('close')">
                        <SvgIcon name="icon/close" />
                        <div class="bu3-reg">{{ text }}</div>
                    </SelectableButton>
                </RowDiv>
            </CardDiv>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        classes: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: 'OK'
        }
    },
    emits: ['close']
}
</script>
<style scoped>
.card {
    padding: 2.5rem;
    background-color: var(--lite-gray-white, #FFFFFF);
    display: flex;
    flex-direction: column;
    gap: 4.5rem;
}

.alert-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.alert-content,
.footer.row {
    margin-left: 0;
}
</style>