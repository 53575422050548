import { createRouter, createWebHistory } from 'vue-router';
import { getAuth, getIsAdmin, setAuth, showWelcomeScreen } from '@/utils/functions';
import store from '@/store';
import bus from '@/eventBus';
import AccessCardDelivery from '@/views/onboarding/AccessCardDelivery.vue';
import AddAccessCard from '@/views/onboarding/AddAccessCard.vue';
import AddBusiness from './views/onboarding/AddBusiness.vue';
import AllDone from '@/views/onboarding/AllDone.vue';
import CardsPersonal from '@/views/onboarding/CardsPersonal.vue';
import CardsBusiness from './views/onboarding/CardsBusiness.vue';
import CongratulationsView from '@/views/onboarding/CongratulationsView.vue';
import DriverDetails from './views/onboarding/DriverDetails.vue';
import KnowingYou from './views/onboarding/KnowingYou.vue';
import PersonalPolicy from '@/views/onboarding/PersonalPolicy.vue';
import PersonalUse from '@/views/onboarding/PersonalUse.vue';
import PoliciesView from './views/onboarding/PoliciesView.vue';
import WelcomeView from './views/onboarding/WelcomeView.vue';

const routes = [
    // Auth required
    { path: '/', redirect: '/home' },
    { path: '/home', name: 'Home', component: () => import('@/views/HomeView.vue') },
    { path: '/history', name: 'Booking History', component: () => import('@/views/booking/BookingHistory.vue') },
    { path: '/contact', name: 'Contact', component: () => import('@/views/ContactView.vue') },
    { path: '/profile', name: 'Profile', component: () => import('@/views/ProfileView.vue') },
    { path: '/search', name: 'Search Booking', component: () => import('@/views/SearchBooking.vue') },
    { path: '/booking', name: 'Configure Booking', component: () => import('@/views/CustomBooking.vue') },
    { path: '/booking/view/:id', name: 'View Booking', component: () => import('@/views/booking/ViewBooking.vue'), props: true },
    { path: '/booking/join/:id', name: 'Join Booking', component: () => import('@/views/booking/CarpoolBooking.vue'), props: true },
    { path: '/booking/:id', name: 'Booking Details', component: () => import('@/views/booking/BookingDetail.vue'), props: true },
    { path: '/fleet/manager', name: 'Mobility Center', component: () => import('@/views/FleetManager.vue') },
    { path: '/fleet/insights', name: 'Insights', component: () => import('@/views/fleet-manager/InsightReports.vue') },
    { path: '/fleet/report', name: 'Reports', component: () => import('@/views/fleet-manager/UserReports.vue') },
    { path: '/fleet/preview', name: 'Preview Report', component: () => import('@/views/fleet-manager/reports/ReportPreview.vue') },
    { path: '/fleet/insight/:index', name: 'Insight', component: () => import('@/views/fleet-manager/InsightsFrame.vue'), props: true },
    { path: '/fleet/reports/:page', name: 'Download Report', component: () => import('@/views/fleet-manager/reports/ReportView.vue'), props: true },
    { path: '/fleet/:framed+', name: 'Legacy', component: () => import('@/views/fleet-manager/FramedPage.vue'), props: true },
    // No auth
    { path: '/login', name: 'Login', component: () => import('@/views/LoginView.vue') },
    { path: '/reset', name: 'Reset Password', component: () => import('@/views/ResetPassword.vue') },
    { path: '/documents/:page', name: 'Document', component: () => import('@/views/DocumentView.vue'), props: true },
    { path: '/onboarding/join', name: 'Join', component: () => import('@/views/onboarding/JoinView.vue') },
    { path: '/onboarding/verify-email', name: 'Verify your Email', component: () => import('@/views/onboarding/VerifyEmail.vue') },
    // Token required
    { path: '/profile/verified', name: 'Email verification', component: () => import('@/views/profile/EmailVerify.vue') },
    { path: '/onboarding/verified', name: 'Email verified', component: () => import('@/views/onboarding/VerifiedView.vue') },
    { path: '/onboarding/welcome', name: 'Welcome', component: WelcomeView },
    { path: '/onboarding/getting-to-know', name: 'Getting to know you', component: KnowingYou },
    { path: '/onboarding/driver-details', name: 'Driver details', component: DriverDetails },
    { path: '/onboarding/policies', name: 'Policies', component: PoliciesView },
    { path: '/onboarding/cards', name: 'Cards - Business', component: AddBusiness },
    { path: '/onboarding/add-cards', name: 'Add cards - Business', component: CardsBusiness },
    { path: '/onboarding/personal-use', name: 'Personal usage', component: PersonalUse },
    { path: '/onboarding/personal-card', name: 'Personal card', component: CardsPersonal },
    { path: '/onboarding/personal-policy', name: 'Personal Policy', component: PersonalPolicy },
    { path: '/onboarding/add-access-card', name: 'Add Access Card', component: AddAccessCard },
    { path: '/onboarding/send-access-card', name: 'Send Access Card', component: AccessCardDelivery },
    { path: '/onboarding/all-done', name: 'Almost done', component: AllDone },
    { path: '/onboarding/congratulations', name: 'Congratulations', component: CongratulationsView },
    { path: '/:pathMatch(.*)*', name: 'Not found', component: () => import('@/views/NotFound.vue') },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0)
    }
});

router.beforeEach(async (to, from, next) => {
    try {
        if (to.query.zup) {
            setAuth({ access_token: to.query.zup, refresh_token: null });

            // Wait for profile loading to complete
            await store.dispatch('loadProfile', { router, bus });
            if (store.isAdmin) await store.dispatch('fetchMenu');

            if (showWelcomeScreen() === null) {
                store.commit('setShowWelcome', true);
            }

            // Strip `zup` from the URL
            // eslint-disable-next-line
            const { zup, ...remainingQuery } = to.query;
            return next({ path: to.path, query: remainingQuery, replace: true });
        }

        const auth = getAuth();
        const isAdmin = getIsAdmin();
        const titlePrefix = window.config.VUE_APP_TITLE_PREFIX || 'Zilch';
        document.title = titlePrefix + (to.name ? (' > ' + to.name) : '');

        if (((to.path === '/login') && auth) || (to.path.startsWith('/fleet') && ((isAdmin === 'false') || !isAdmin))) {
            return next({ name: 'Home' });
        }

        if ((to.path === '/login') ||
            (to.path === '/reset') ||
            (to.path === '/reset/') ||
            (to.path === '/profile/verified') ||
            (to.path === '/profile/verified/') ||
            to.path.includes('onboarding') ||
            to.path.includes('documents')) {
            return next();
        }

        if (!auth) {
            return next({ path: '/login' });
        }

        if (to.path.startsWith('/fleet')) {
            document.body.style.background = 'var(--lite-gray-grey-3)'; // Change background to new color
        } else {
            document.body.style.background = ''; // Reset to the default background
        }

        next(); // Allow navigation
    } catch (error) {
        console.error('Error loading profile from cross-login:', error);
        next({ path: '/login' });
    }
});

export default router;
