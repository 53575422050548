const GLOBAL_ENV = {
    API_HOST: 'https://api.zilch.ninja',
    LEGACY_HOST: 'https://portal.zrs.nz',
    REPORT_HOST: 'https://data.zilch.nz/reports',
    STRIPE_API_KEY: 'pk_test_51PyKigP2iL7s3fWGnre3C59ltlrpmjr5sNzSUkiDSmL8zwhvOftlKV56AIyUTjsdO8Js0vRDvV8ksWsPH2cZHaXe00ZmVZnP0T',
    STRIPE_SECRET_KEY: 'sk_test_51PyKigP2iL7s3fWGiazxecSB5EvWaIfF2Wq0sFCoftrFl7maYfrCK4ztVYy2K2fc4bthAiFWSkaD7EummCIcO5z200WpMNDoMA',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCpyNC2h8u_PtT4M0XYMuqU6w5ReSdYD98',
    ADDY_URL: 'https://api-nz.addysolutions.com/search/',
    ADDY_KEY: '0b77a3446d8b4ea186592c7598d99f9b',
    ADDY_SECRET: 'd1c36aecaec449fb9376c392c0082105',
    ADD_SUPER_FLOW: true,
}

const ENV_SANDBOX = {
    ...GLOBAL_ENV,
    API_HOST: 'https://api.zilch.services'
}

const ENV_DEV = {
    ...GLOBAL_ENV,
    API_HOST: 'https://api.zilch.services'
}

const ENV_UAT = {
    ...GLOBAL_ENV,
    LEGACY_HOST: 'https://account.zilch.nz',
    STRIPE_API_KEY: 'pk_live_51PyKigP2iL7s3fWGUgsinTgDdZLP0FSyVuLCi1FZKJDxllITRDLtdz3FJwI46DfgQCg4x137JtVJZF5nfXsGg5uD00IlrioQl4',
    STRIPE_SECRET_KEY: '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',
    // ADDY_KEY: 'f5d76db6b99e4d13a3db499309d11a00',
}

const ENV_PROD = {
    ...ENV_UAT,
    ADD_SUPER_FLOW: false,
    API_HOST: 'https://zup.zilch.nz',
}

let env = ENV_PROD;
if (process.env.NODE_ENV === 'development') {
    env = ENV_DEV;
} else if (process.env.NODE_ENV === 'sandbox') {
    env = ENV_SANDBOX;
} else if (process.env.NODE_ENV === 'staging') {
    env = ENV_UAT;
} else {
    env = ENV_PROD;
}
const environment = env;

export default environment;